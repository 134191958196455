import { Icon } from "@mui/material";
import AuthWall from "modules/AuthWall/AuthWall";
import OrganizationSettingsPage from "pages/organization/settings";
import OrganizationSubscriptionPage from "pages/organization/subscription";
import OrganizationAddressesPage from "pages/organization/addresses";
import OrganizationAddressCreatePage from "pages/organization/addresses/create";
import OrganizationAddressEditPage from "pages/organization/addresses/edit";
import OrganizationEquipmentPiecesPage from "pages/organization/equipmentPieces";
import OrganizationEquipmentPieceCreatePage from "pages/organization/equipmentPieces/create";
import OrganizationEquipmentPieceEditPage from "pages/organization/equipmentPieces/edit";
import OrganizationAdditionalCostsPage from "pages/organization/additionalCosts";
import OrganizationAdditionalCostCreatePage from "pages/organization/additionalCosts/create";
import OrganizationAdditionalCostEditPage from "pages/organization/additionalCosts/edit";
import OrganizationTransferTrucksPage from "pages/organization/transferTrucks";
import OrganizationTransferTruckCreatePage from "pages/organization/transferTrucks/create";
import OrganizationTransferTruckEditPage from "pages/organization/transferTrucks/edit";
import OrganizationCrewsPage from "pages/organization/crews";
import OrganizationCrewCreatePage from "pages/organization/crews/create";
import OrganizationCrewEditPage from "pages/organization/crews/edit";
import DataUploadsPage from "pages/organization/dataUploads";
import DataUploadsImportPage from "pages/organization/dataUploads/create";
// import OrganizationPlantsCreatePage from "pages/organization/plants/create";
import OrganizationPlantsPage from "pages/organization/plants";
import OrganizationPlantsCreatePage from "pages/organization/plants/create";
import OrganizationPlantsEditPage from "pages/organization/plants/edit";
import ProposalSettingsPage from "pages/organization/proposal-settings";
import EmailSettingsPage from "pages/organization/email-settings";
import EmployeesListPage from "pages/organization/employees";
import CreateEmployeePage from "pages/organization/employees/create";
import EditEmployeePage from "pages/organization/employees/edit";
import UpdateOrganizationPage from "pages/organization/update";
import UsersPage from "pages/users";
import NotificationsIndex from "pages/notifications";
import NotificationsEdit from "pages/notifications/edit";
import NotificationsCreate from "pages/notifications/create";
import OrganizationProductTypesPage from "pages/organization/product-types";
import Reports from "pages/organization/reports";
import AuthWallSubscriber from "modules/AuthWallSubscriber/AuthWallSubscriber";
import OrganizationLinkedServices from "pages/organization/linkedServices";
import FinalizeOrganizationLinkedService from "pages/organization/linkedServices/finalize";
import QuickbooksSettings from "pages/organization/linkedServices/Quickbooks/settings";
import QuickbooksDesktopQueueList from "pages/organization/linkedServices/Quickbooks/queueList";
import ProjectFiles from "pages/organization/projectFiles";

const organizationRoutes = [
  {
    type: "collapse",
    name: "Product Settings",
    key: "organization",
    icon: <Icon fontSize="medium">factory</Icon>,
    collapse: [
      {
        name: "Products",
        key: "product-types.list",
        route: "/product-types",
        icon: <Icon fontSize="medium">add_road</Icon>,
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationProductTypesPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Equipment",
        key: "equipment-pieces",
        route: "/organization/equipment",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationEquipmentPiecesPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Crews",
        key: "crews",
        route: "/organization/crews",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationCrewsPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Create Address",
        key: "create-address",
        route: "/organization/settings/create",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationAddressCreatePage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Edit Address",
        key: "edit-address",
        route: "/organization/addresses/:addressId",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationAddressEditPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Additional Costs",
        key: "additional-costs",
        route: "/organization/additional-costs",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationAdditionalCostsPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Create Additional Cost",
        key: "create-additional-cost",
        route: "/organization/additional-costs/create",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationAdditionalCostCreatePage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Edit Additional Cost",
        key: "edit-additional-cost",
        route: "/organization/additional-costs/:additionalCostId",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationAdditionalCostEditPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Create Equipment Piece",
        key: "create-equipment-piece",
        route: "/organization/equipment/create",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationEquipmentPieceCreatePage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Edit Equipment Piece",
        key: "edit-equipment-piece",
        route: "/organization/equipment/:equipmentPieceId",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationEquipmentPieceEditPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },

      {
        name: "Plants",
        key: "plants",
        route: "/organization/plants",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationPlantsPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Create Plant",
        key: "create-plant",
        route: "/organization/plants/create",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationPlantsCreatePage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Update Plant",
        key: "update",
        route: "/organization/plants/:plantId",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationPlantsEditPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Transfer Trucks",
        key: "transfer-trucks",
        route: "/organization/transfer-trucks",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationTransferTrucksPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Create Transfer Truck",
        key: "create-transfer-truck",
        route: "/organization/transfer-trucks/create",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationTransferTruckCreatePage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Update Transfer Truck",
        key: "update-transfer-truck",
        route: "/organization/transfer-trucks/:truckId",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationTransferTruckEditPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },

      {
        type: "internal",
        name: "Create Crew",
        key: "create-crew",
        route: "/organization/crews/create",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationCrewCreatePage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Update Crew",
        key: "update-crew",
        route: "/organization/crews/:crewId",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationCrewEditPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Create Employee",
        key: "create-employee",
        route: "/organization/employees/create",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <CreateEmployeePage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Update Employee",
        key: "update-employee",
        route: "/organization/employees/:employeeId/update",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <EditEmployeePage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
    ],
  },
  {
    type: "collapse",
    name: "Organization Settings",
    key: "organization-settings",
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "Users",
        key: "users.list",
        route: "/users",
        component: (
          <AuthWall>
            <UsersPage />
          </AuthWall>
        ),
      },
      {
        name: "Data Imports",
        key: "data-uploads.list-data-uploads",
        route: "/data-imports",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <DataUploadsPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Import",
        key: "data-uploads.create-data-upload",
        route: "/data-imports/import",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <DataUploadsImportPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Notification Triggers",
        key: "email-triggers.list-email-triggers",
        route: "/email-triggers",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <NotificationsIndex />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Edit",
        type: "internal",
        key: "email-triggers.update-email-triggers",
        route: "/email-triggers/edit/:notificationId",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <NotificationsEdit />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        type: "internal",
        name: "Create",
        key: "email-triggers.create-email-triggers",
        route: "/email-triggers/create",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <NotificationsCreate />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Proposal Settings",
        key: "organization.proposal-settings",
        title: "Proposal Settings",
        route: "/organization/proposal-settings",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <ProposalSettingsPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Organization Info",
        key: "organization.settings",
        route: "/organization/settings",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationSettingsPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Subscription",
        key: "organization.subscription",
        route: "/organization/subscription",
        component: (
          <AuthWall>
            <OrganizationSubscriptionPage />
          </AuthWall>
        ),
      },
      {
        name: "Email Settings",
        key: "organization.email-settings",
        title: "Email Settings",
        route: "/organization/email-settings",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <EmailSettingsPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Employees",
        key: "employees",
        title: "Employees",
        route: "/organization/employees",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <EmployeesListPage />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Generate Reports",
        key: "reports",
        title: "Generate Reports",
        route: "/organization/reports",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <Reports />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Project Files",
        key: "projectFiles",
        title: "Project Files",
        route: "/project-files",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <ProjectFiles />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Linked Services",
        key: "services",
        title: "Linked Services",
        route: "/organization/linked-services",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <OrganizationLinkedServices />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Finalize Linked Service",
        key: "FinalizeService",
        title: "Finalize Linked Service",
        route: "/organization/finalize-linked-service/:serviceName",
        type: "internal",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <FinalizeOrganizationLinkedService />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Quickbooks Settings",
        key: "QuickbooksSettings",
        title: "Quickbooks Settings",
        route: "/organization/linked-services/quickbooks/settings",
        type: "internal",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <QuickbooksSettings />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
      {
        name: "Quickbooks Desktop Queue List",
        key: "QuickbooksDesktopQueueList",
        title: "Quickbooks Desktop Queue List",
        route: "/organization/linked-services/quickbooks-desktop/queue",
        type: "internal",
        component: (
          <AuthWall>
            <AuthWallSubscriber>
              <QuickbooksDesktopQueueList />
            </AuthWallSubscriber>
          </AuthWall>
        ),
      },
    ],
  },
] as const;

export default organizationRoutes;
