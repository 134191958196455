import { Card } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "layouts/DashboardLayout/DashboardLayout";
import DashboardNavbar from "partials/DashboardNavbar/DashboardNavbar";
import React from "react";

export default function DashboardPage({
  children,
  renderNavbar = true,
}: {
  children: React.ReactNode;
  renderNavbar?: boolean;
}) {
  return (
    <DashboardLayout>
      <MDBox
        sx={{
          "@media print": {
            display: "none",
          },
        }}
        display={{ sx: "block", xl: "none" }}
      >
        <DashboardNavbar showBreadcrumbs={false} renderNavbar={renderNavbar} />
      </MDBox>
      <MDBox my={3}>
        <Card>
          <MDBox p={3}>{children}</MDBox>
        </Card>
      </MDBox>
    </DashboardLayout>
  );
}
