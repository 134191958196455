import { GetPlantQuery, Plant, useGetPlantLazyQuery } from "generated/graphql";
import { useMemo } from "react";

export default function useGetPlantLazy() {
  const [query, { data, loading, error }] = useGetPlantLazyQuery();

  const getData = ({ data }: { data?: GetPlantQuery }) => {
    return data?.getPlant ?? null;
  };

  const getPlant = async (id: Plant["id"]) => {
    return await query({ variables: { id } });
  };

  const plant = useMemo(() => {
    return (data && data.getPlant) || null;
  }, [data]);

  return [getPlant, { data, loading, error, plant, getData }] as const;
}
